var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import sanitizeHtml from "sanitize-html";
import Typography from "../../ui/Typography/Typography";
import { useAppDispatch } from "../../../store";
import { displayModal, selectRegistrationFormLegals } from "@adm-media/adam-store-plugin";
import { FAQ_MODAL, LEGAL_MODAL } from "../../modals";
import { useSelector } from "react-redux";
import "./SanitizeHtml.scss";
var Link = Typography.Link;
export var SanitizeHtmlString = function (_a) {
    var stringToSanitize = _a.stringToSanitize, tag = _a.tag, className = _a.className;
    if (!stringToSanitize)
        return null;
    var clean = sanitizeHtml(stringToSanitize, {
        allowedTags: ["p", "b", "i", "em", "strong", "a", "ul", "li", "ol", "br"],
        allowedAttributes: {
            a: ["href", "target", "style"],
            p: ["style"],
            b: ["style"],
            i: ["style"],
            em: ["style"],
            strong: ["style"],
            ul: ["style"],
            li: ["style"],
            ol: ["style"]
        }
    });
    var Tag = tag;
    return _jsx(Tag, { className: className, dangerouslySetInnerHTML: { __html: clean } });
};
export var SanitizeHtml = function (_a) {
    var stringToSanitize = _a.stringToSanitize, tag = _a.tag, className = _a.className, legalsIndex = _a.legalsIndex;
    if (!stringToSanitize)
        return null;
    var dispatch = useAppDispatch();
    var legals = useSelector(selectRegistrationFormLegals);
    var knownTagsAndActions = [
        {
            tag: "FAQ_POPUP",
            action: function () { return dispatch(displayModal({ name: FAQ_MODAL })); }
        },
        {
            tag: "TOS_POPUP",
            action: function () {
                var _a;
                return dispatch(displayModal({
                    name: LEGAL_MODAL,
                    props: { content: (_a = legals[legalsIndex || 0]) === null || _a === void 0 ? void 0 : _a.content }
                }));
            }
        },
        {
            tag: "EXTERNAL_LINK",
            linkAttributes: { rel: "external", target: "_blank" }
        }
    ];
    var matches = knownTagsAndActions === null || knownTagsAndActions === void 0 ? void 0 : knownTagsAndActions.filter(function (tagAndActions) {
        var baseRegex = new RegExp("\\[" + tagAndActions.tag + "](.*?)\\[\\/" + tagAndActions.tag + "]");
        var findAllRegex = new RegExp(baseRegex, "g");
        return stringToSanitize === null || stringToSanitize === void 0 ? void 0 : stringToSanitize.match(findAllRegex);
    });
    if (matches.length === 0)
        return _jsx(SanitizeHtmlString, { tag: tag, className: className, stringToSanitize: stringToSanitize });
    else {
        var strings_1 = [stringToSanitize];
        matches.forEach(function (match) {
            var stringsArray = strings_1.map(function (element) {
                var splitted = element.split(new RegExp("(\\[" + match.tag + "].*?\\[\\/" + match.tag + "])"));
                return splitted;
            });
            strings_1 = stringsArray.reduce(function (prev, next) { return __spreadArray(__spreadArray([], prev, true), next, true); }, []);
        });
        var Tag = tag;
        return (_jsx(Tag, __assign({ className: className }, { children: strings_1.map(function (str) {
                return matches.map(function (match) {
                    var baseRegex = new RegExp("\\[" + match.tag + "](.*?)\\[\\/" + match.tag + "]");
                    var findAllRegex = new RegExp(baseRegex, "g");
                    if (str.match(findAllRegex)) {
                        var text = str.replace("[".concat(match.tag, "]"), "").replace("[/".concat(match.tag, "]"), "");
                        return (_jsx(React.Fragment, { children: match.action ? (_jsx("b", __assign({ className: match.tag + "-action", onClick: function () { return match.action(); }, style: { cursor: "pointer", display: "inline", textDecoration: "underline" } }, { children: _jsx(SanitizeHtmlString, { tag: "span", stringToSanitize: text }) }))) : match.linkAttributes ? (_jsx(Link, __assign({ className: match.tag + "-action", rel: match.linkAttributes.rel, target: match.linkAttributes.target, href: "https://" + text, style: { cursor: "pointer", fontWeight: "bold", textDecoration: "underline" } }, { children: text }))) : null }, text));
                    }
                    else {
                        return (_jsx(React.Fragment, { children: _jsx(SanitizeHtmlString, { className: match.tag + "-text", tag: "span", stringToSanitize: str }) }, str));
                    }
                });
            }) })));
    }
};
