var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { addSubmittedQuestion, hideModal, selectCurrentEpisode, selectMeCurrentStreamInfo, sendQuestionThunk, selectNewQuestion } from "@adm-media/adam-store-plugin";
import QAForm from "../../ui/QAForm/QAForm";
import { QA_QUESTION_MODAL } from "..";
import { useAppDispatch } from "../../../store";
import { useNotification } from "../../../hooks/useNotification";
/**
 * Modal visibile by Moderator to add new question to chairman
 * @returns
 */
export var QAQuestionModal = function () {
    var dispatch = useAppDispatch();
    var loadingNewQuestion = useSelector(selectNewQuestion).loading;
    var episodeId = useSelector(selectCurrentEpisode).id;
    var _a = useSelector(selectMeCurrentStreamInfo), streamId = _a.streamId, streamName = _a.streamName;
    var notification = useNotification().notification;
    // Submit new question from modal, if "Send immediately" it will be submitted immediately
    var handleSubmitNewQuestion = function (_a) {
        var confirmed = _a.confirmed, question = _a.question;
        return dispatch(sendQuestionThunk({
            episode_id: episodeId,
            confirmed: confirmed,
            original_content: question,
            edited_content: confirmed ? question : undefined,
            stream_id: streamId,
            stream_name: streamName
        })).then(function (_a) {
            var payload = _a.payload;
            if (!(payload === null || payload === void 0 ? void 0 : payload.code)) {
                dispatch(hideModal({ name: QA_QUESTION_MODAL }));
                if (confirmed) {
                    dispatch(addSubmittedQuestion(payload));
                    notification.success({ message: "Question sent to chairman" });
                }
            }
        });
    };
    return (_jsx("div", __assign({ "data-testid": "qa-moderator-add-question-modal" }, { children: _jsx(QAForm, { title: "Add question", withConfirmation: true, confirmationLabel: "Send immediately", submitButtonText: "Send question", loading: loadingNewQuestion === "pending", onSubmit: handleSubmitNewQuestion }) })));
};
export var getQAQuestionModalProps = function () {
    return {};
};
