var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Spin as AntSpin } from "antd";
import { LoadingOutlinedIcon } from "../Icons/Icons";
import "./Spin.scss";
var Spin = function (_a) {
    var children = _a.children, className = _a.className, _b = _a.fullPage, fullPage = _b === void 0 ? false : _b, customIcon = _a.customIcon, _c = _a.oldVersion, oldVersion = _c === void 0 ? false : _c, transparent = _a.transparent, dataTestid = _a.dataTestid, spinning = _a.spinning, props = __rest(_a, ["children", "className", "fullPage", "customIcon", "oldVersion", "transparent", "dataTestid", "spinning"]);
    var loadingIndicator = customIcon ? customIcon : !oldVersion ? _jsx(LoadingOutlinedIcon, {}) : undefined;
    var _d = useState(spinning || false), isLoading = _d[0], setIsLoading = _d[1];
    useEffect(function () {
        if (spinning === true)
            setIsLoading(true);
        else if (spinning === false)
            setIsLoading(false);
    }, [spinning]);
    return (_jsx(AntSpin, __assign({ indicator: loadingIndicator, size: !oldVersion ? "large" : "default", className: "".concat(className || "", " spin ").concat(fullPage ? "spin--full-page" : "", " ").concat(transparent ? "spin--transparent" : ""), "data-testid": dataTestid, spinning: spinning || isLoading }, props, { children: children || _jsx("div", {}) })));
};
export default Spin;
