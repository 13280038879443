var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import classNames from "classnames";
import Affix from "../../ui/Affix/Affix";
import { useSelector } from "react-redux";
import { selectMedia, selectStyles } from "@adm-media/adam-store-plugin";
import { BlockWrapper } from "../../wrappers/BlockWrapper/BlockWrapper";
import { ConfigProvider } from "antd";
import "./Navbar.scss";
var Navbar = function (_a) {
    var actions = _a.actions, _b = _a.affix, affix = _b === void 0 ? true : _b, animation = _a.animation, props = __rest(_a, ["actions", "affix", "animation"]);
    var header_logo_desktop = useSelector(selectMedia).header_logo_desktop;
    var _c = useState(false), affixed = _c[0], toggleAffixed = _c[1];
    var styles = useSelector(selectStyles);
    var navbarClassNames = classNames("navbar", {
        "navbar--affixed": affixed && affix
    });
    var navbar = (_jsx(BlockWrapper, __assign({ className: navbarClassNames }, props, { children: _jsxs("div", __assign({ className: "navbar__container" }, { children: [_jsx("div", __assign({ className: "navbar__logo" }, { children: _jsx("img", { src: header_logo_desktop === null || header_logo_desktop === void 0 ? void 0 : header_logo_desktop.url, alt: "Logo" }) })), _jsx(ConfigProvider, __assign({ theme: {
                        components: {
                            Select: {
                                colorBorder: (styles === null || styles === void 0 ? void 0 : styles.default_border_color) || "#d9d9d9",
                                selectorBg: "white"
                            }
                        }
                    } }, { children: actions && _jsx("div", __assign({ className: "navbar__actions" }, { children: actions })) }))] })) })));
    var handleChangeAffix = function (affixed) { return toggleAffixed(affixed || false); };
    return affix ? (_jsx(Affix, __assign({ onChange: handleChangeAffix, className: classNames("affix") }, { children: navbar }))) : (navbar);
};
export default Navbar;
