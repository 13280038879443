var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { VOD } from "../types/player";
import { getLocalStorageOffsetFieldName } from "./local-storage";
/** Only available for VOD - take currentTime from localstorage based on episodeId */
var startVODFrom = function (episodeId) {
    var timeStored = localStorage.getItem(getLocalStorageOffsetFieldName(episodeId));
    if (timeStored) {
        try {
            var time = parseFloat(timeStored);
            return time;
        }
        catch (_a) {
            return null;
        }
    }
    else
        return null;
};
var onTimeUpdate = function (currentTime, channelStatus, episodeId) {
    if ([VOD].includes(channelStatus)) {
        var timeInt = currentTime === null || currentTime === void 0 ? void 0 : currentTime.toString();
        localStorage.setItem(getLocalStorageOffsetFieldName(episodeId), timeInt);
    }
};
var getTextTrack = function (player) {
    var lng = null;
    player === null || player === void 0 ? void 0 : player.textTracks.forEach(function (track) {
        // https://videojs.com/guides/text-tracks/#showing-tracks-programmatically
        if (track.mode === "showing" && track.kind === "subtitles") {
            lng = track.language;
        }
    });
    return lng;
};
var getAudioTrack = function (trackEvent) {
    var _a, _b;
    var currentAudioTrack;
    if ((_a = trackEvent === null || trackEvent === void 0 ? void 0 : trackEvent.track) === null || _a === void 0 ? void 0 : _a.enabled) {
        currentAudioTrack = (_b = trackEvent === null || trackEvent === void 0 ? void 0 : trackEvent.track) === null || _b === void 0 ? void 0 : _b.language;
    }
    else {
        currentAudioTrack = null;
    }
    return currentAudioTrack;
};
var transformIsoLngTwoDigits = function (langId) {
    var lng = null;
    if (!langId || typeof langId === null)
        lng = null;
    switch (langId) {
        case "eng":
            lng = "en";
            break;
        case "deu":
            lng = "de";
            break;
        case "ger":
            lng = "de";
            break;
        case "spa":
            lng = "es";
            break;
        case "fra":
            lng = "fr";
            break;
        case "ita":
            lng = "it";
            break;
        case "por":
            lng = "pt";
            break;
        case "zho":
            lng = "zh";
            break;
        case "jpn":
            lng = "ja";
            break;
        case "und":
            lng = null;
            break;
        default:
            lng = langId;
            break;
    }
    return lng;
};
var transformIsoLngThreeDigits = function (langId) {
    var lng = null;
    if (!langId || typeof langId === null)
        lng = null;
    switch (langId) {
        case "en":
            lng = "eng";
            break;
        case "de":
            lng = "ger";
            break;
        case "es":
            lng = "spa";
            break;
        case "fr":
            lng = "fra";
            break;
        case "it":
            lng = "ita";
            break;
        case "pt":
            lng = "por";
            break;
        case "zh":
            lng = "zho";
            break;
        case "ja":
            lng = "jpn";
            break;
        default:
            lng = langId;
            break;
    }
    return lng;
};
/**
 * Programmatically exit full screen of video
 */
var exitFullScreenMode = function () {
    var isInFullScreen = (document.fullscreenElement && document.fullscreenElement !== null) ||
        (document.webkitFullscreenElement && document.webkitFullscreenElement !== null) ||
        (document.mozFullScreenElement && document.mozFullScreenElement !== null) ||
        (document.msFullscreenElement && document.msFullscreenElement !== null);
    if (isInFullScreen) {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        }
        else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        }
        else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        }
        else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
    }
};
var changeCCLanguageOfPlayer = function (player, langId) {
    var _a;
    try {
        (_a = player === null || player === void 0 ? void 0 : player.textTracks.filter(function (track) {
            if ((track === null || track === void 0 ? void 0 : track.mode) !== "disabled") {
                return track;
            }
        })) === null || _a === void 0 ? void 0 : _a.forEach(function (track) {
            track.mode = "disabled";
        });
        // Enable the text track for a specific language. Note: Here we are searching the label. You can also do x.language for the ISO 3 letter language code.
        var trackToActivate = player.textTracks.filter(function (track) {
            if (track.kind === "subtitles" && (track.language === transformIsoLngTwoDigits(langId) || track.language === langId)) {
                return track;
            }
        });
        if (trackToActivate && trackToActivate.length)
            trackToActivate[0].mode = "showing";
    }
    catch (e) { }
};
var changeAudioLanguageOfPlayer = function (player, langId) { return __awaiter(void 0, void 0, void 0, function () {
    var i;
    var _a, _b, _c;
    return __generator(this, function (_d) {
        if (((_a = player === null || player === void 0 ? void 0 : player.audioTracks) === null || _a === void 0 ? void 0 : _a.length) < 2)
            return [2 /*return*/];
        (_b = player === null || player === void 0 ? void 0 : player.audioTracks) === null || _b === void 0 ? void 0 : _b.forEach(function (track) {
            track.enabled = false;
        });
        for (i = 0; i < ((_c = player === null || player === void 0 ? void 0 : player.audioTracks) === null || _c === void 0 ? void 0 : _c.length); i++) {
            if (player.audioTracks[i].language === langId) {
                player.audioTracks[i].enabled = true;
            }
        }
        return [2 /*return*/];
    });
}); };
export { startVODFrom, onTimeUpdate, exitFullScreenMode, getTextTrack, transformIsoLngThreeDigits, getAudioTrack, transformIsoLngTwoDigits, changeCCLanguageOfPlayer, changeAudioLanguageOfPlayer };
