import { jsx as _jsx } from "react/jsx-runtime";
import { hideModal } from "@adm-media/adam-store-plugin";
import Row from "../../ui/Row/Row";
import classNames from "classnames";
import { useAppDispatch } from "../../../store";
import { LOGIN_MODAL } from "..";
import AuthBlock from "../../functional/AuthBlock/AuthBlock";
import "./LoginModal.scss";
export var LoginModal = function () {
    var dispatch = useAppDispatch();
    var onLoginSuccess = function (payload) {
        if (payload === null || payload === void 0 ? void 0 : payload.token)
            dispatch(hideModal({ name: LOGIN_MODAL }));
    };
    var onRegisterSuccess = function () {
        dispatch(hideModal({ name: LOGIN_MODAL }));
    };
    return (_jsx(Row, { children: _jsx(AuthBlock, { defaultAuthState: "login", onLoginSuccess: onLoginSuccess, onRegisterSuccess: onRegisterSuccess }) }));
};
export var getLoginModalProps = function () {
    return {
        modalClassName: classNames("login-modal")
    };
};
