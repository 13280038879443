var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import Form from "../../ui/Form/Form";
import Input from "../../ui/Input/Input";
import Button from "../../ui/Buttons/Button";
import Typography from "../../ui/Typography/Typography";
import { useSelector } from "react-redux";
import { closeActiveSessionUserThunk, loginUserThunk, selectAppContent, selectIsFirstLoginFormEnabled, selectLocaleLanguage, selectLogin } from "@adm-media/adam-store-plugin";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import { useAppDispatch } from "../../../store";
import { LocaleChangeOmitCall, useChangeAppLocale } from "../../../hooks/useChangeAppLocale";
import { CloseActiveSession } from "./CloseActiveSession/CloseActiveSession";
import { useNotification } from "../../../hooks/useNotification";
import { BlockWrapper } from "../../wrappers/BlockWrapper/BlockWrapper";
import { FirstLogin } from "./FirstLogin/FirstLogin";
import Space from "../../ui/Space/Space";
import { SanitizeHtml } from "../../ui/SanitizeHtml/SanitizeHtml";
import "./LoginForm.scss";
var Item = Form.Item;
var Password = Input.Password;
var Link = Typography.Link, Title = Typography.Title;
export var LoginForm = function (_a) {
    var onSuccessCallback = _a.onSuccessCallback, props = __rest(_a, ["onSuccessCallback"]);
    var dispatch = useAppDispatch();
    var form = Form.useForm()[0];
    var isFirstLoginFormEnabled = useSelector(selectIsFirstLoginFormEnabled);
    var locale = useSelector(selectLocaleLanguage);
    var contents = useSelector(selectAppContent);
    var changeAppLocale = useChangeAppLocale().changeAppLocale;
    var _b = useState(undefined), activeSession = _b[0], setActiveSession = _b[1];
    var notification = useNotification().notification;
    var loadingLogin = useSelector(selectLogin).loading;
    var _c = useState(false), isForgotPassword = _c[0], setIsForgotPassword = _c[1];
    var _d = useState(false), isFirstLogin = _d[0], setIsFirstLogin = _d[1];
    var handleLogin = function (values) {
        var loginData = typeof locale === "string"
            ? {
                email: values.email,
                password: values.password,
                lang_id: locale
            }
            : {
                email: values.email,
                password: values.password
            };
        dispatch(loginUserThunk(loginData))
            .unwrap()
            .then(function (payload) {
            var _a, _b, _c;
            onSuccessCallback && onSuccessCallback(payload);
            if (!!((_a = payload === null || payload === void 0 ? void 0 : payload.me) === null || _a === void 0 ? void 0 : _a.lang_id) && ((_b = payload === null || payload === void 0 ? void 0 : payload.me) === null || _b === void 0 ? void 0 : _b.lang_id) !== locale) {
                changeAppLocale({ newLocale: ((_c = payload === null || payload === void 0 ? void 0 : payload.me) === null || _c === void 0 ? void 0 : _c.lang_id) || "en", callsToSkip: [LocaleChangeOmitCall.episodes] });
            }
        })
            .catch(function (errorResponse) {
            var _a, _b, _c, _d, _e;
            if ((errorResponse === null || errorResponse === void 0 ? void 0 : errorResponse.status) === 403)
                setActiveSession((_a = errorResponse === null || errorResponse === void 0 ? void 0 : errorResponse.data) === null || _a === void 0 ? void 0 : _a.concurrent);
            else {
                var errorToShow = Array.isArray((_b = errorResponse === null || errorResponse === void 0 ? void 0 : errorResponse.data) === null || _b === void 0 ? void 0 : _b.errors)
                    ? (_c = errorResponse === null || errorResponse === void 0 ? void 0 : errorResponse.data) === null || _c === void 0 ? void 0 : _c.errors.join(", ")
                    : typeof ((_d = errorResponse === null || errorResponse === void 0 ? void 0 : errorResponse.data) === null || _d === void 0 ? void 0 : _d.message) === "string"
                        ? (_e = errorResponse === null || errorResponse === void 0 ? void 0 : errorResponse.data) === null || _e === void 0 ? void 0 : _e.message
                        : "There was an error while performing the login";
                notification.error({ message: errorToShow });
            }
        });
    };
    var handleCloseActiveSession = function () {
        dispatch(closeActiveSessionUserThunk({
            concurrent: activeSession
        }))
            .unwrap()
            .then(function () {
            setActiveSession(undefined);
            notification.showGenericSuccess();
        })
            .catch(function () { return notification.showGenericError(); })
            .finally(function () { return setActiveSession(undefined); });
    };
    return (_jsx(BlockWrapper, __assign({ "data-testid": "login-form", className: "login-form" }, props, { children: isForgotPassword ? (_jsxs(_Fragment, { children: [_jsx(Title, __assign({ level: props.titleLevel || 2 }, { children: _jsx(SanitizeHtml, { stringToSanitize: contents.reset_password_text || "Request a password reset link", tag: "span" }) })), _jsx(ForgotPassword, { setIsForgotPassword: setIsForgotPassword })] })) : activeSession ? (_jsxs(_Fragment, { children: [_jsx(Title, __assign({ level: props.titleLevel || 2 }, { children: contents.already_logged_in_message })), _jsx(CloseActiveSession, { handleCloseActiveSession: handleCloseActiveSession })] })) : isFirstLogin ? (_jsxs(_Fragment, { children: [_jsx(Title, __assign({ level: props.titleLevel || 2 }, { children: contents.login_form_title })), _jsx(FirstLogin, { onSuccessCallback: onSuccessCallback, setIsFirstLogin: setIsFirstLogin })] })) : (_jsxs(_Fragment, { children: [_jsx(Title, __assign({ level: props.titleLevel || 2 }, { children: contents.login_form_title })), _jsxs(Form, __assign({ form: form, onFinish: handleLogin, layout: "vertical" }, { children: [_jsx(Item, __assign({ name: "email", "data-testid": "login-email-field", label: contents.email, rules: [
                                {
                                    required: true,
                                    message: contents.error_email_required
                                }
                            ] }, { children: _jsx(Input, { placeholder: contents.email, autoComplete: "on" }) })), _jsx(Item, __assign({ name: "password", "data-testid": "login-password-field", label: contents.password || "Password", rules: [
                                {
                                    required: true,
                                    message: contents.error_password_required
                                }
                            ], extra: _jsx(Link, __assign({ "data-testid": "forgot-password-link", style: { paddingTop: 5 }, onClick: function () { return setIsForgotPassword(true); } }, { children: _jsx(SanitizeHtml, { stringToSanitize: contents.forgot_password_text || "Forgot password?", tag: "span" }) })) }, { children: _jsx(Password, { placeholder: contents.password || "Password", autoComplete: "on" }) })), _jsxs(Space, __assign({ direction: "horizontal" }, { children: [_jsx(Button, __assign({ className: "login-form__submit-button", type: "primary", "data-testid": "login-submit-button", loading: loadingLogin === "pending", htmlType: "submit" }, { children: contents.login })), !!isFirstLoginFormEnabled && (_jsx(Button, __assign({ "data-testid": "is-first-login-button", htmlType: "button", onClick: function () { return setIsFirstLogin(true); } }, { children: _jsx("b", { children: contents.is_first_login_text || "Is this your first login?" }) })))] }))] }))] })) })));
};
