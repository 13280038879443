var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { LoginForm } from "../../blocks/LoginForm/LoginForm";
import Typography from "../../ui/Typography/Typography";
import { useSelector } from "react-redux";
import { selectAppContent, selectAreEpisodesLoading, selectEpisodes, selectForceHideRegistrationForm, selectRegistrationFormId } from "@adm-media/adam-store-plugin";
import RegistrationForm from "../../blocks/RegistrationForm/RegistrationForm";
import { allRegistrationFormsPropsEnrichers } from "../../layout/enrich-props/enrich-registration-form-props";
import { SanitizeHtml } from "../../ui/SanitizeHtml/SanitizeHtml";
var Link = Typography.Link;
var AuthBlock = function (_a) {
    var defaultAuthState = _a.defaultAuthState, onLoginSuccess = _a.onLoginSuccess, onRegisterSuccess = _a.onRegisterSuccess, props = __rest(_a, ["defaultAuthState", "onLoginSuccess", "onRegisterSuccess"]);
    var _b = useState(defaultAuthState), authState = _b[0], setAuthState = _b[1];
    var episodes = useSelector(selectEpisodes);
    var contents = useSelector(selectAppContent);
    var registrationFormId = useSelector(selectRegistrationFormId);
    var episodesLoading = useSelector(selectAreEpisodesLoading);
    var forceHideRegistrationForm = useSelector(selectForceHideRegistrationForm);
    var episodesAreLoaded = episodesLoading === "idle" || episodesLoading === "succeeded";
    var registrationFormProps = registrationFormId && typeof (allRegistrationFormsPropsEnrichers === null || allRegistrationFormsPropsEnrichers === void 0 ? void 0 : allRegistrationFormsPropsEnrichers[registrationFormId]) === "function"
        ? allRegistrationFormsPropsEnrichers === null || allRegistrationFormsPropsEnrichers === void 0 ? void 0 : allRegistrationFormsPropsEnrichers[registrationFormId]()
        : { inputs: [], onChange: undefined };
    var episodesWithOpenSubscription = episodes && episodes.filter(function (item) { return item.is_subscriptions_open; });
    var isAvailableRegistration = episodesWithOpenSubscription.length >= 1 && episodesAreLoaded && !!!forceHideRegistrationForm ? true : false;
    return (_jsxs(_Fragment, { children: [authState === "login" ? (_jsx(LoginForm, __assign({}, props, { noGutter: true, verticalSpacing: "none", titleLevel: 3, onSuccessCallback: onLoginSuccess }))) : (_jsx(RegistrationForm, __assign({ noGutter: true, noBackground: true, verticalSpacing: "none", hideGoToLogin: true, hideLogo: true }, registrationFormProps, { onSuccessCallback: onRegisterSuccess, titleLevel: 3 }))), _jsx("div", __assign({ style: { marginTop: 20, display: isAvailableRegistration ? "block" : "none" } }, { children: authState === "login" ? (_jsx(Link, __assign({ onClick: function () { return setAuthState("register"); } }, { children: _jsx(SanitizeHtml, { stringToSanitize: contents.back_to_registration_form || "Go to registration form", tag: "div" }) }))) : (_jsx(Link, __assign({ onClick: function () { return setAuthState("login"); } }, { children: _jsx(SanitizeHtml, { stringToSanitize: contents.go_to_login_form, tag: "div" }) }))) }))] }));
};
export default AuthBlock;
