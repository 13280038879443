var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { isNull } from "../utils/obj-check";
import { momentTimezone } from "../utils/moment";
import moment from "moment";
import { useSelector } from "react-redux";
import { selectAppContent, selectSelfApp } from "@adm-media/adam-store-plugin";
var useAgenda = function () {
    var timezone = useSelector(selectSelfApp).timezone;
    var contents = useSelector(selectAppContent);
    var formatAgenda = function (_a) {
        var agendaItem = _a.agendaItem;
        var start;
        if (!timezone)
            start = moment(agendaItem.start_at);
        else
            start = momentTimezone.utc(agendaItem.start_at).tz(timezone);
        var startHours = "0".concat(start.get("hours")).slice(-2);
        var startMinutes = "0".concat(start.get("minutes")).slice(-2);
        return __assign(__assign({}, agendaItem), { start_at: "".concat(startHours, ":").concat(startMinutes), label: !isNull(agendaItem.duration) ? "".concat(agendaItem.duration, " ").concat(contents.minutes) : undefined });
    };
    return { formatAgenda: formatAgenda };
};
export default useAgenda;
