var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { addQuestion, fetchQuestionsThunk, selectEventId, selectMeCurrentStreamInfo, selectUser, displayModal, selectSelectedEpisodeId, selectEpisodes, setSelectedEpisodeId, selectQuestions, selectMarkAsReadQuestion, selectUpdateQuestion } from "@adm-media/adam-store-plugin";
import { subscribeToQAQuestionChannel } from "../../../pusher/subscribe-to-qa-questions-channel";
import Spin from "../../ui/Spin/Spin";
import Button from "../../ui/Buttons/Button";
import QuestionCard from "../../ui/QuestionCard/QuestionCard";
import { QA_QUESTION_MODAL } from "../../modals";
import useBreakpoint from "../../../hooks/useBreakpoint";
import { Typography } from "antd";
import { Select } from "antd";
import { useNotification } from "../../../hooks/useNotification";
import Divider from "../../ui/Divider/Divider";
import Tabs from "../../ui/Tabs/Tabs";
import { useAppDispatch } from "../../../store";
import "./QuestionsBoard.scss";
var Title = Typography.Title;
export var QuestionsBoard = function (_a) {
    var cols = _a.cols, onSelect = _a.onSelect, onCancelSelection = _a.onCancelSelection, onSubmit = _a.onSubmit, withAddNew = _a.withAddNew, onDeleteQuestion = _a.onDeleteQuestion;
    var dispatch = useAppDispatch();
    var _b = useBreakpoint(), isMiniTablet = _b.isMiniTablet, isMobile = _b.isMobile;
    var selectedEpisodeId = useSelector(selectSelectedEpisodeId);
    var episodes = useSelector(selectEpisodes);
    var eventId = useSelector(selectEventId);
    var _c = useSelector(selectMeCurrentStreamInfo), streamId = _c.streamId, streamName = _c.streamName;
    var _d = useSelector(selectUser), token = _d.token, isModerator = _d.isModerator, isChairman = _d.isChairman;
    var _e = useSelector(selectQuestions), items = _e.items, selected = _e.selected, submitted = _e.submitted, loadingQuestions = _e.loading;
    var loadingMarkAsRead = useSelector(selectMarkAsReadQuestion).loading;
    var loadingUpdateQuestion = useSelector(selectUpdateQuestion).loading;
    var notification = useNotification().notification;
    var _f = useState(undefined), qaQuestionChannel = _f[0], setQaQuestionChannel = _f[1];
    var _g = useState(cols[0].title), activeTab = _g[0], setActiveTab = _g[1];
    var submitButtonText = isModerator ? "Send question to chairman" : isChairman ? "Mark as read" : undefined;
    var handleToggleShowForm = function () { return dispatch(displayModal({ name: QA_QUESTION_MODAL })); };
    useEffect(function () {
        if (episodes.length === 1)
            dispatch(setSelectedEpisodeId(episodes[0].id));
    }, [episodes]);
    useEffect(function () {
        if (selectedEpisodeId) {
            if (typeof streamId === null || !streamId)
                dispatch(fetchQuestionsThunk({ episode_id: selectedEpisodeId }));
            else
                dispatch(fetchQuestionsThunk({ episode_id: selectedEpisodeId, stream_id: streamId }));
        }
        if (eventId && token && selectedEpisodeId) {
            var episodeID_1 = String(selectedEpisodeId);
            setQaQuestionChannel(function (previousChannel) {
                if (previousChannel === null || previousChannel === void 0 ? void 0 : previousChannel.unsubscribe)
                    previousChannel === null || previousChannel === void 0 ? void 0 : previousChannel.unsubscribe();
                return subscribeToQAQuestionChannel({
                    token: token,
                    appId: eventId,
                    episodeId: episodeID_1,
                    isModerator: isModerator,
                    isChairman: isChairman
                }, function (question) {
                    dispatch(addQuestion(question));
                    notification.success({ message: "You received a new question!" });
                });
            });
        }
    }, [eventId, token, selectedEpisodeId]);
    useEffect(function () {
        return function () {
            if (qaQuestionChannel) {
                qaQuestionChannel.unsubscribe();
            }
        };
    }, []);
    var isTabs = isMobile || isMiniTablet;
    var columns = function () { return [
        {
            key: cols[0].title,
            label: cols[0].title,
            children: (_jsxs("div", __assign({ style: { display: isTabs && activeTab !== cols[0].title ? "none" : "flex", flex: isTabs ? "1 0 100%" : "1 0 30%" }, className: "questions-board__column", "data-testid": "question-board-first-column" }, { children: [_jsx("div", __assign({ className: "questions-board__column-title-container" }, { children: _jsx(Title, __assign({ level: 4 }, { children: cols[0].title })) })), _jsx("div", __assign({ className: "questions-board__inner-column" }, { children: _jsx("div", __assign({ className: "questions-board__items-container" }, { children: items.map(function (_a) {
                                var createdAt = _a.created_at, id = _a.id, originalContent = _a.original_content, editedContent = _a.edited_content, subscriber = _a.subscriber;
                                return (_jsx(QuestionCard, { hoverable: true, onClick: function () { return onSelect(id); }, date: createdAt, questionId: id, label: subscriber === null || subscriber === void 0 ? void 0 : subscriber.email, questionText: isModerator ? originalContent : editedContent, user: "".concat(subscriber === null || subscriber === void 0 ? void 0 : subscriber.firstname, " ").concat(subscriber === null || subscriber === void 0 ? void 0 : subscriber.lastname), onDeleteQuestion: onDeleteQuestion }, id));
                            }) })) }))] }), cols[0].title))
        },
        {
            key: cols[1].title,
            label: cols[1].title,
            children: (_jsxs("div", __assign({ style: { display: isTabs && activeTab !== cols[1].title ? "none" : "flex", flex: isTabs ? "1 0 100%" : "1 0 calc(40% - 24px)" }, className: "questions-board__column", "data-testid": "question-board-second-column" }, { children: [_jsxs("div", __assign({ className: "questions-board__column-title-container" }, { children: [_jsx(Title, __assign({ level: 4 }, { children: cols[1].title })), withAddNew && (_jsx(Button, __assign({ className: "button", onClick: handleToggleShowForm, "data-testid": "ask-new-question-btn" }, { children: "Add new question" })))] })), _jsx("div", __assign({ className: "questions-board__inner-column" }, { children: _jsx("div", __assign({ className: "questions-board__items-container" }, { children: selected.map(function (_a) {
                                var createdAt = _a.created_at, id = _a.id, originalContent = _a.original_content, editedContent = _a.edited_content, subscriber = _a.subscriber;
                                return (
                                // This is the single card moderator will approve to chairman
                                _jsx(QuestionCard, { date: createdAt, editable: isModerator, withActions: isModerator || isChairman, questionId: id, onDismiss: function () { return onCancelSelection(id); }, onSubmit: function (question) {
                                        onSubmit({
                                            id: id,
                                            episode_id: selectedEpisodeId,
                                            edited_content: question,
                                            original_content: originalContent,
                                            confirmed: true,
                                            stream_id: streamId,
                                            stream_name: streamName
                                        });
                                    }, submitButtonText: submitButtonText, cancelButtonText: "Cancel", label: subscriber === null || subscriber === void 0 ? void 0 : subscriber.email, questionText: isModerator ? originalContent : editedContent, user: "".concat(subscriber === null || subscriber === void 0 ? void 0 : subscriber.firstname, " ").concat(subscriber === null || subscriber === void 0 ? void 0 : subscriber.lastname) }, id));
                            }) })) }))] }), cols[1].title))
        },
        {
            key: cols[2].title,
            label: cols[2].title,
            children: (_jsxs("div", __assign({ style: { display: isTabs && activeTab !== cols[2].title ? "none" : "flex", flex: isTabs ? "1 0 100%" : "1 0 30%" }, className: "questions-board__column", "data-testid": "question-board-third-column" }, { children: [_jsx("div", __assign({ className: "questions-board__column-title-container" }, { children: _jsx(Title, __assign({ level: 4 }, { children: cols[2].title })) })), _jsx("div", __assign({ className: "questions-board__inner-column" }, { children: _jsx("div", __assign({ className: "questions-board__items-container" }, { children: submitted.map(function (_a) {
                                var updatedAt = _a.updated_at, id = _a.id, editedContent = _a.edited_content, subscriber = _a.subscriber, originalContent = _a.original_content;
                                return (_jsx(QuestionCard, { date: updatedAt, questionId: id, label: subscriber === null || subscriber === void 0 ? void 0 : subscriber.email, questionText: editedContent ? editedContent : originalContent, submitted: true, user: "".concat(subscriber === null || subscriber === void 0 ? void 0 : subscriber.firstname, " ").concat(subscriber === null || subscriber === void 0 ? void 0 : subscriber.lastname) }, id));
                            }) })) }))] }), cols[2].title))
        }
    ]; };
    var loadingTransparent = loadingMarkAsRead === "pending" || loadingUpdateQuestion === "pending";
    var loading = loadingQuestions === "uninitialized" || loadingTransparent || loadingQuestions === "pending";
    return (_jsxs(_Fragment, { children: [_jsx(Spin, { spinning: !!selectedEpisodeId && loading, fullPage: true, transparent: loadingTransparent }), _jsxs("div", __assign({ className: "questions-board" }, { children: [_jsxs("div", __assign({ className: "questions-board__top" }, { children: [_jsx(Divider, {}), (episodes === null || episodes === void 0 ? void 0 : episodes.length) > 1 && (_jsxs(_Fragment, { children: [_jsx(Select, { options: episodes.map(function (episode) {
                                            return { label: episode.title, value: episode.id };
                                        }), "data-testid": "episode-selector", placeholder: "No episode selected", style: { width: 260 }, value: selectedEpisodeId, onChange: function (value) { return dispatch(setSelectedEpisodeId(value)); } }), _jsx(Divider, {})] })), isTabs && selectedEpisodeId !== null && (_jsx(Tabs, { defaultActiveKey: activeTab, onChange: function (newActiveTab) { return setActiveTab(newActiveTab); }, items: columns().map(function (_a) {
                                    var key = _a.key, label = _a.label;
                                    return ({ key: key, label: label });
                                }) }))] })), _jsx("div", __assign({ className: "questions-board__columns-container" }, { children: selectedEpisodeId === null ? (_jsx(Typography.Paragraph, __assign({ "data-testid": "select-episode-text" }, { children: "Please select an episode to continue" }))) : loading ? (_jsx("div", {})) : (columns().map(function (column) { return column.children; })) }))] }))] }));
};
