var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useSelector } from "react-redux";
import { selectEpisodes, setIsDrawerOpen } from "@adm-media/adam-store-plugin";
import SelectEpisodeCard from "../../components/functional/SelectEpisodeCard/SelectEpisodeCard";
import { useEffect } from "react";
import Row from "../../components/ui/Row/Row";
import Col from "../../components/ui/Col/Col";
import { useSetCurrentEpisode } from "../../hooks/useSetCurrentEpisode";
import { useAppDispatch } from "../../store";
import "./EpisodeProposal.scss";
export var EpisodeProposalPage = function (_a) {
    var playerChannelAction = _a.playerChannelAction;
    var dispatch = useAppDispatch();
    var episodes = useSelector(selectEpisodes);
    var setCurrentEpisode = useSetCurrentEpisode().setCurrentEpisode;
    useEffect(function () {
        window.scrollTo(0, 0);
        dispatch(setIsDrawerOpen(false));
    }, []);
    return (_jsx(Row, __assign({ "data-testid": "episode-proposal-container", className: "episode-proposal", justify: "center" }, { children: _jsx(Col, __assign({ span: 20, className: "episode-proposal__available-episodes" }, { children: _jsx(Row, __assign({ gutter: [24, 24] }, { children: episodes.map(function (episode) {
                    return (_jsx(Col, __assign({ xxl: 8, xl: 12, lg: 12, md: 24, sm: 24, xs: 24, style: { display: "flex" }, onClick: function () { return setCurrentEpisode({ episodeId: episode.id }); } }, { children: _jsx(SelectEpisodeCard, { episode: episode, playerChannelAction: playerChannelAction }) }), episode.id));
                }) })) })) })));
};
