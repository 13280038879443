var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
//@ts-nocheck
import { useState } from "react";
import classNames from "classnames";
import { CalendarOutlined, LogoutOutlined, MenuOutlined, MessageOutlined, PieChartOutlined, QuestionCircleOutlined, TeamOutlined, InfoCircleOutlined, FileOutlined, LeftOutlined, FilePdfOutlined, FileImageOutlined, DeleteOutlined, GiftOutlined as AntGiftOutlined, FunnelPlotOutlined as AntFunnelPlotOutlined, DownloadOutlined as AntDownloadOutlined, CoffeeOutlined as AntCoffeeOutlined, NotificationOutlined as AntNotificationOutlined, PushpinOutlined as AntPushpinOutlined, QrcodeOutlined as AntQrcodeOutlined, VideoCameraOutlined as AntVideoCameraOutlined, StarOutlined as AntStarOutlined, AreaChartOutlined as AntAreaChartOutlined, DownOutlined as AntDownOutlined, UpOutlined as AntUpOutlined, MinusOutlined as AntMinusOutlined, PlusOutlined as AntPlusOutlined, SoundFilled as AntSoundFilled, RollbackOutlined as AntRollbackOutlined, QuestionOutlined as AntQuestionOutlined, LoadingOutlined as AntLoadingOutlined } from "@ant-design/icons";
import "./Icons.scss";
export var FileIcon = function (props) { return _jsx(FileOutlined, __assign({}, props)); };
export var ImageIcon = function (props) { return _jsx(FileImageOutlined, __assign({}, props)); };
export var PdfIcon = function (props) { return _jsx(FilePdfOutlined, __assign({}, props)); };
export var DeleteIcon = function (props) { return _jsx(DeleteOutlined, __assign({}, props)); };
export var QuizIcon = function (props) { return _jsx(AntQuestionOutlined, __assign({}, props)); };
export var InfoIcon = function (props) { return _jsx(InfoCircleOutlined, __assign({}, props)); };
export var EvaluationIcon = function (props) { return _jsx(AntStarOutlined, __assign({}, props)); };
export var GoBackIcon = function (props) { return _jsx(AntRollbackOutlined, __assign({}, props)); };
export var AgendaIcon = function (props) { return _jsx(CalendarOutlined, __assign({}, props)); };
export var LogoutIcon = function (props) { return _jsx(LogoutOutlined, __assign({}, props)); };
export var MenuIcon = function (props) { return _jsx(MenuOutlined, __assign({}, props)); };
export var PoolingIcon = function (props) { return _jsx(PieChartOutlined, __assign({}, props)); };
export var QAIcon = function (props) { return _jsx(MessageOutlined, __assign({}, props)); };
export var QuestionIcon = function (props) { return _jsx(QuestionCircleOutlined, __assign({}, props)); };
export var SpeakersIcon = function (props) { return _jsx(TeamOutlined, __assign({}, props)); };
export var LeftOutlinedIcon = function (props) { return _jsx(LeftOutlined, __assign({}, props)); };
export var GiftOutlined = function (props) { return _jsx(AntGiftOutlined, __assign({}, props)); };
export var FunnelPlotOutlined = function (props) { return _jsx(AntFunnelPlotOutlined, __assign({}, props)); };
export var DownloadOutlined = function (props) { return _jsx(AntDownloadOutlined, __assign({}, props)); };
export var CoffeeOutlined = function (props) { return _jsx(AntCoffeeOutlined, __assign({}, props)); };
export var NotificationOutlined = function (props) { return _jsx(AntNotificationOutlined, __assign({}, props)); };
export var PushpinOutlined = function (props) { return _jsx(AntPushpinOutlined, __assign({}, props)); };
export var QrcodeOutlined = function (props) { return _jsx(AntQrcodeOutlined, __assign({}, props)); };
export var VideoCameraOutlined = function (props) { return _jsx(AntVideoCameraOutlined, __assign({}, props)); };
export var StarOutlined = function (props) { return _jsx(AntStarOutlined, __assign({}, props)); };
export var AreaChartOutlined = function (props) { return _jsx(AntAreaChartOutlined, __assign({}, props)); };
export var UpOutlined = function (props) { return _jsx(AntUpOutlined, __assign({}, props)); };
export var DownOutlined = function (props) { return _jsx(AntDownOutlined, __assign({}, props)); };
export var SoundFilledIcon = function (props) { return _jsx(AntSoundFilled, __assign({}, props)); };
export var LoadingOutlinedIcon = function (props) { return _jsx(AntLoadingOutlined, __assign({}, props)); };
export var ExpandIcon = function (_a) {
    var onClick = _a.onClick, expanded = _a.expanded, props = __rest(_a, ["onClick", "expanded"]);
    return expanded ? (_jsx(AntMinusOutlined, __assign({}, props, { className: "speaker__expand", onClick: onClick }))) : (_jsx(AntPlusOutlined, __assign({}, props, { className: "speaker__expand", onClick: onClick })));
};
export var HamburgerIcon = function (_a) {
    var _b;
    var _c = _a.open, open = _c === void 0 ? false : _c, className = _a.className, onClick = _a.onClick, removeActiveClass = _a.removeActiveClass;
    var _d = useState(open), active = _d[0], toggleActive = _d[1];
    var cs = classNames("hamburger-icon", (_b = {},
        _b[className] = className,
        _b.active = open,
        _b));
    var handleToggleHamburger = function () {
        onClick();
        toggleActive(!active);
    };
    return (_jsxs("div", __assign({ role: "presentation", className: cs, onClick: handleToggleHamburger }, { children: [_jsx("span", {}), _jsx("span", {}), _jsx("span", {})] })));
};
