var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import useBreakpoint from "../../../hooks/useBreakpoint";
import { useDispatch, useSelector } from "react-redux";
import { selectEventId, selectCurrentDrawer, selectCurrentEpisode, selectEpisodes, selectUser, displayModal, toggleDrawer, selectAppContent, selectAppLanguages } from "@adm-media/adam-store-plugin";
import Button from "../Buttons/Button";
import { LogoutButton } from "../Buttons/LogoutButton/LogoutButton";
import { useSetCurrentEpisode } from "../../../hooks/useSetCurrentEpisode";
import { EVALUATION_MODAL, QUIZ_MODAL } from "../../modals";
import { isEmpty } from "../../../utils/obj-check";
import { Space } from "antd";
import SelectLang from "../Select/SelectLang/SelectLang";
import { HamburgerIcon } from "../Icons/Icons";
import { useLocation, useNavigate } from "react-router-dom";
import "./NavbarActions.scss";
import { useIsEvaluationFormAvailable } from "../../../hooks/useIsEvaluationFormAvailable";
import { useShowQuizButton } from "../../../hooks/useShowQuizButton";
import { Paths } from "../../../pages";
var NavbarActions = function () {
    var navigate = useNavigate();
    var location = useLocation();
    var dispatch = useDispatch();
    var _a = useBreakpoint(), isMobile = _a.isMobile, isMiniTablet = _a.isMiniTablet, isTablet = _a.isTablet;
    var setCurrentEpisode = useSetCurrentEpisode().setCurrentEpisode;
    var episodes = useSelector(selectEpisodes);
    var _b = useIsEvaluationFormAvailable(), isEvaluationFormAvailable = _b.isEvaluationFormAvailable, isEvaluationFormAlreadyAnswered = _b.isEvaluationFormAlreadyAnswered;
    var _c = useShowQuizButton(), isQuizOpen = _c.isQuizOpen, isQuizAlreadyAnswered = _c.isQuizAlreadyAnswered;
    var contents = useSelector(selectAppContent);
    var languages = useSelector(selectAppLanguages);
    var isWatchRoute = location.pathname === "/watch/player";
    var eventId = useSelector(selectEventId);
    var _d = useSelector(selectUser), token = _d.token, identity = _d.identity;
    var isDrawerOpen = useSelector(selectCurrentDrawer);
    var navbar_custom_buttons_configuration = useSelector(selectCurrentEpisode).navbar_custom_buttons_configuration;
    var showEvaluationModal = function () { return dispatch(displayModal({ name: EVALUATION_MODAL })); };
    var showQuizModal = function () {
        dispatch(displayModal({
            name: QUIZ_MODAL
        }));
    };
    var logout = _jsx(LogoutButton, { className: "navbar-logout-button" }, "logout");
    var evaluation = isEvaluationFormAvailable && (_jsx(Button, __assign({ disabled: isEvaluationFormAlreadyAnswered, "data-testid": "evaluation-form-button", type: "default", className: "evaluation-button", onClick: showEvaluationModal }, { children: contents.evaluation_form_title || "Evaluation form" }), "evaluation"));
    var quiz = isQuizOpen && (_jsx(Button, __assign({ type: "default", "data-testid": "quiz-button", disabled: isQuizAlreadyAnswered, onClick: showQuizModal }, { children: contents.quiz_title || "Quiz" }), "quiz"));
    var backToEpisodes = (episodes === null || episodes === void 0 ? void 0 : episodes.length) > 1 && (_jsx(Button, __assign({ type: "default", onClick: function () {
            setCurrentEpisode({ episodeId: null });
            navigate(Paths.watch);
        }, className: "select-episode" }, { children: contents.change_episode }), "backToEpisodes"));
    var selectLanguages = languages.length > 1 && _jsx(SelectLang, {}, "selectLanguages");
    var buttonSlots = {
        100: [],
        90: isWatchRoute ? [evaluation] : [],
        80: [],
        70: isWatchRoute ? [quiz] : [],
        60: [],
        50: isWatchRoute ? [backToEpisodes] : [],
        40: [],
        30: [selectLanguages],
        20: [],
        10: [logout],
        0: []
    };
    var buttonSlotsKeys = Object.keys(buttonSlots);
    if (navbar_custom_buttons_configuration === null || navbar_custom_buttons_configuration === void 0 ? void 0 : navbar_custom_buttons_configuration.length) {
        navbar_custom_buttons_configuration.forEach(function (item, i) {
            var _a;
            if (!item.visible && isEmpty(identity)) {
                return;
            }
            var label = (item === null || item === void 0 ? void 0 : item.label[identity.lang_id]) || (item === null || item === void 0 ? void 0 : item.label["en"]);
            var mapObj = {
                "{EVENT_CODE}": eventId,
                "{USER_TOKEN}": token
            };
            var href = item.href
                ? item.href.replace(/\{EVENT_CODE\}|\{USER_TOKEN\}/gi, function (matched) {
                    return mapObj[matched];
                })
                : undefined;
            var btnElement = (_jsx(Button, __assign({ type: "default", role: "link", href: href, target: item.target, style: item === null || item === void 0 ? void 0 : item.style, className: item.class }, { children: label }), i));
            (_a = buttonSlots[(item === null || item === void 0 ? void 0 : item.position) || 100]) === null || _a === void 0 ? void 0 : _a.push(btnElement);
        });
    }
    var buttons = [];
    buttonSlotsKeys.reverse().forEach(function (k) {
        buttons === null || buttons === void 0 ? void 0 : buttons.push(buttonSlots[k]);
    });
    var closableSidebar = isMobile || isMiniTablet || isTablet;
    return (_jsx("div", __assign({ "data-testid": "navbar-actions" }, { children: !isWatchRoute || !closableSidebar ? (_jsx(Space, { children: buttons })) : (_jsxs("div", __assign({ className: "navbar-actions__hamburger-icon-container" }, { children: [selectLanguages, _jsx(HamburgerIcon, { open: isDrawerOpen, onClick: function () { return dispatch(toggleDrawer()); } })] }))) })));
};
export default NavbarActions;
