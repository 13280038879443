var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Navbar from "../../blocks/Navbar/Navbar";
import NavbarActions from "../../ui/NavbarActions/NavbarActions";
import { Outlet } from "react-router";
import Row from "../../ui/Row/Row";
import Contacts from "../../blocks/Contacts/Contacts";
import Footer from "../../blocks/Footer/Footer";
import "./PageLayout.scss";
export var PageLayout = function (_a) {
    var children = _a.children;
    return (_jsxs("div", __assign({ className: "page-layout" }, { children: [_jsx(Navbar, { actions: _jsx(NavbarActions, {}), affix: true, verticalSpacing: "small" }), _jsx(Outlet, {}), children, _jsxs(Row, { children: [_jsx(Contacts, {}), _jsx(Footer, {})] })] })));
};
