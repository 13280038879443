var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import Col from "../../ui/Col/Col";
import { SpeakerCard } from "../../ui/SpeakerCard/SpeakerCard";
import { useSelector } from "react-redux";
import { selectAppContent, selectEpisodes } from "@adm-media/adam-store-plugin";
import { Typography, Row } from "antd";
import { rowGutter } from "../configs";
import { BlockWrapper } from "../../wrappers/BlockWrapper/BlockWrapper";
import "./Speakers.scss";
import { useRef } from "react";
import { useAnimationClassName } from "../../../hooks/useAnimationClassName";
export var Speakers = function (_a) {
    var _b = _a.viewType, viewType = _b === void 0 ? "grid" : _b, speakers = _a.speakers, hiddenSpeakersId = _a.hiddenSpeakersId, isDrawerElement = _a.isDrawerElement, title = _a.title, animation = _a.animation, props = __rest(_a, ["viewType", "speakers", "hiddenSpeakersId", "isDrawerElement", "title", "animation"]);
    var contents = useSelector(selectAppContent);
    var episodes = useSelector(selectEpisodes);
    var titleRef = useRef(null);
    var titleAnimationClass = useAnimationClassName({ ref: titleRef, animation: animation });
    var contentRef = useRef(null);
    var contentAnimationClass = useAnimationClassName({ ref: contentRef, animation: animation });
    var baseClass = "block-speakers";
    var modifyerClass = baseClass + (isDrawerElement ? "--drawer" : "--element");
    var blockClassName = classNames(baseClass, modifyerClass);
    var speakersToHide = speakers
        ? hiddenSpeakersId || []
        : episodes === null || episodes === void 0 ? void 0 : episodes.reduce(function (prev, next) {
            if (!(next === null || next === void 0 ? void 0 : next.hidden_speakers_cards))
                return __spreadArray([], prev, true);
            else
                return __spreadArray(__spreadArray([], prev, true), next.hidden_speakers_cards, true);
        }, []);
    var speakersToShow = (speakers || episodes.reduce(function (prev, next) { return __spreadArray(__spreadArray([], prev, true), next.speakers, true); }, [])).filter(function (speaker) { return !(speakersToHide === null || speakersToHide === void 0 ? void 0 : speakersToHide.includes(speaker.id)); });
    var itemsClassNames = classNames("speakers__list", {
        "block-speakers__list--grid": viewType === "grid",
        "block-speakers__list--list": viewType === "list"
    });
    var itemsColSize = viewType === "grid"
        ? {
            lg: 8,
            md: 24,
            sm: 24,
            xl: 8,
            xxl: 8
        }
        : { span: 24 };
    if (speakersToShow.length === 0)
        return null;
    return (_jsxs(BlockWrapper, __assign({ className: blockClassName }, props, { children: [title !== null && contents.speakers_title && (_jsx(Typography.Title, __assign({ ref: titleRef, className: titleAnimationClass, style: { marginBottom: 20 }, level: props.titleLevel || 2 }, { children: contents.speakers_title }))), _jsx(Row, __assign({ ref: contentRef, gutter: rowGutter, className: "".concat(itemsClassNames, " ").concat(contentAnimationClass) }, { children: speakersToShow &&
                    speakersToShow.map(function (speaker) {
                        return (_jsx(Col, __assign({}, itemsColSize, { children: _jsx(SpeakerCard, { name: "".concat(speaker.firstname, " ").concat(speaker.lastname), id: speaker.id, avatar: speaker.media_url, role: speaker.role, description: speaker.description }) }), speaker.id));
                    }) }))] })));
};
