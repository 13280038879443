import { useEffect } from "react";
import loadFont from "../utils/load-font";
import { selectStyles } from "@adm-media/adam-store-plugin";
import { useSelector } from "react-redux";
export var useLoadFonts = function () {
    var styles = useSelector(selectStyles);
    useEffect(function () {
        if (!(styles === null || styles === void 0 ? void 0 : styles.defaultFontFamily))
            return;
        loadFont(styles === null || styles === void 0 ? void 0 : styles.defaultFontFamily);
    }, [styles]);
};
