var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { selectLayoutUi } from "@adm-media/adam-store-plugin";
import { useSelector } from "react-redux";
import { Layout } from "../components/layout/Layout";
var Hero = {
    children: [
        { props: { affix: true, withLoginButton: true }, component: "Navbar" },
        { props: { centered: true }, component: "Hero" },
        { component: "Agenda" },
        { props: { centered: true }, component: "Contacts" },
        { props: { centered: true }, component: "Footer" }
    ]
};
var ThemeF2 = {
    children: [
        {
            props: {
                affix: false,
                withLoginButton: true
            },
            component: "Navbar"
        },
        {
            props: {
                xl: {
                    alignment: "l",
                    dimension: "2-3"
                }
            },
            component: "Episodes"
        },
        {
            props: {
                xl: {
                    alignment: "r",
                    dimension: "1-3"
                }
            },
            component: "RegistrationForm"
        },
        {
            props: {
                centered: true
            },
            component: "Contacts"
        },
        {
            props: {
                centered: true
            },
            component: "Footer"
        }
    ]
};
var ThemeL2 = {
    children: [
        {
            props: {
                xl: {
                    alignment: "l",
                    dimension: "1-3"
                }
            },
            component: "RegistrationForm"
        },
        {
            props: {
                xl: {
                    alignment: "r",
                    dimension: "2-3"
                },
                viewType: "list"
            },
            component: "Episodes"
        },
        {
            props: {
                centered: true
            },
            component: "Contacts"
        },
        {
            props: {
                alignContent: "center"
            },
            component: "Footer"
        }
    ]
};
var ThemeL22 = {
    children: [
        {
            props: {
                xl: {
                    alignment: "l",
                    dimension: "1-2"
                }
            },
            component: "RegistrationForm"
        },
        {
            props: {
                xl: {
                    alignment: "r",
                    dimension: "1-2"
                },
                viewType: "list"
            },
            component: "Episodes"
        },
        {
            props: {
                centered: true
            },
            component: "Contacts"
        },
        {
            props: {
                alignContent: "center"
            },
            component: "Footer"
        }
    ]
};
var HomePage = function () {
    var layoutUi = useSelector(selectLayoutUi);
    return (_jsx("div", __assign({ "data-testid": "home" }, { children: _jsx(Layout, { nodes: layoutUi }) })));
};
export { HomePage };
