import moment from "moment";
import { utcToTimeZone } from "./timezone";
import { convertPHPToMomentFormat } from "./date";
import momentTimezone from "moment-timezone";
var dateFormat = "YYYY-MM-DD HH:mm:ss";
export var getFirstAvailableDateInUTC = function (_a) {
    var _b;
    var datesInUtc = _a.datesInUtc, currentUtcTime = _a.currentUtcTime;
    if ((datesInUtc === null || datesInUtc === void 0 ? void 0 : datesInUtc.length) === 0 || !currentUtcTime)
        return null;
    var sortedUtcDates = datesInUtc === null || datesInUtc === void 0 ? void 0 : datesInUtc.sort(function (a, b) {
        var dateA = new Date(a);
        var dateB = new Date(b);
        return dateA.getTime() - dateB.getTime();
    });
    var nextAvailableLiveDate = null;
    sortedUtcDates === null || sortedUtcDates === void 0 ? void 0 : sortedUtcDates.forEach(function (dateInUtc) {
        if (!!dateInUtc &&
            !momentTimezone.utc(dateInUtc).isBefore() &&
            (nextAvailableLiveDate === null || new Date(nextAvailableLiveDate) > new Date(dateInUtc))) {
            nextAvailableLiveDate = dateInUtc;
        }
    });
    if (nextAvailableLiveDate === null) {
        nextAvailableLiveDate = (_b = sortedUtcDates === null || sortedUtcDates === void 0 ? void 0 : sortedUtcDates.filter(function (date) { return date !== undefined && date !== null; })) === null || _b === void 0 ? void 0 : _b[0];
    }
    return nextAvailableLiveDate;
};
export var getFormattedFirstAvailableDate = function (_a) {
    var liveDatesInUtc = _a.liveDatesInUtc, timezone = _a.timezone, timeFormatType = _a.timeFormatType, dateFormatType = _a.dateFormatType, dsTimezoneAbbreviation = _a.dsTimezoneAbbreviation, timezoneAbbreviation = _a.timezoneAbbreviation, currentUTCTime = _a.currentUTCTime, hideTime = _a.hideTime;
    var liveDateTimeInUTC = getFirstAvailableDateInUTC({
        datesInUtc: liveDatesInUtc,
        currentUtcTime: currentUTCTime
    }) || "";
    var formattedFirstAvailableDate = formatUtcDateInEventTimezone({
        date: liveDateTimeInUTC,
        timezone: timezone,
        timeFormatType: timeFormatType,
        dateFormatType: dateFormatType,
        dsTimezoneAbbreviation: dsTimezoneAbbreviation,
        timezoneAbbreviation: timezoneAbbreviation,
        hideTime: hideTime
    });
    return formattedFirstAvailableDate;
};
export var formatUtcDateInEventTimezone = function (_a) {
    var _b;
    var date = _a.date, timezone = _a.timezone, timeFormatType = _a.timeFormatType, dateFormatType = _a.dateFormatType, dsTimezoneAbbreviation = _a.dsTimezoneAbbreviation, timezoneAbbreviation = _a.timezoneAbbreviation, hideTime = _a.hideTime;
    var timezoneDateTime = utcToTimeZone({ time: date, zone: timezone, format: dateFormat });
    if (!timezoneDateTime)
        return null;
    if (!timeFormatType || !dateFormatType)
        return timezoneDateTime;
    var liveDate = ((_b = timezoneDateTime.split(" ")) === null || _b === void 0 ? void 0 : _b[0]) || "";
    var formattedLiveDate = timezoneDateTime
        ? "".concat(moment(liveDate).format(convertPHPToMomentFormat(dateFormatType)), " ").concat(hideTime
            ? ""
            : "".concat(moment(timezoneDateTime).format(convertPHPToMomentFormat(timeFormatType)), " ").concat(moment.tz(timezoneDateTime, timezone || "utc").isDST() ? dsTimezoneAbbreviation || "" : timezoneAbbreviation || ""))
        : null;
    return formattedLiveDate;
};
