var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Button from "../../ui/Buttons/Button";
import Checkbox from "../../ui/Checkbox/Checkbox";
import Col from "../../ui/Col/Col";
import Form from "../../ui/Form/Form";
import Input from "../../ui/Input/Input";
import Select from "../../ui/Select/Select";
import Typography from "../../ui/Typography/Typography";
import "./RegistrationForm.scss";
import { SanitizeHtml } from "../../ui/SanitizeHtml/SanitizeHtml";
import { useSelector } from "react-redux";
import { displayModal, registerUserThunk, selectAppContent, selectAreEpisodesLoading, selectEpisodes, selectForceHideRegistrationForm, selectMedia, selectRegister, selectRegistrationFormLegals, selectUser, selectVirtual } from "@adm-media/adam-store-plugin";
import { OpenLoginModalButton } from "../../ui/Buttons/OpenLoginModalButton/OpenLoginModalButton";
import { useAppDispatch } from "../../../store";
import { CONFIRM_EMAIL_ON_REGISTER_MODAL, LOGIN_MODAL, THANK_YOU_MODAL, VW_MODAL } from "../../modals";
import { isEmpty } from "../../../utils/obj-check";
import { BlockWrapper } from "../../wrappers/BlockWrapper/BlockWrapper";
import { mapErrorsToFields } from "../../../utils/map-errors-to-fields";
import Space from "../../ui/Space/Space";
import { Logo } from "../../ui/Logo/Logo";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useAnimationClassName } from "../../../hooks/useAnimationClassName";
var Item = Form.Item;
var Text = Typography.Text, Title = Typography.Title;
var Password = Input.Password;
var RegistrationForm = function (_a) {
    var _b;
    var _c;
    var _d = _a.inputs, inputs = _d === void 0 ? [] : _d, onChange = _a.onChange, onSuccessCallback = _a.onSuccessCallback, animation = _a.animation, props = __rest(_a, ["inputs", "onChange", "onSuccessCallback", "animation"]);
    var registerLoading = useSelector(selectRegister).loading;
    var media = useSelector(selectMedia);
    var dispatch = useAppDispatch();
    var form = Form.useForm()[0];
    var loadingRegister = useSelector(selectRegister).loading;
    var contents = useSelector(selectAppContent);
    var episodes = useSelector(selectEpisodes);
    var episodesLoading = useSelector(selectAreEpisodesLoading);
    var legals = useSelector(selectRegistrationFormLegals);
    var _e = useSelector(selectVirtual), active = _e.active, has = _e.has;
    var _f = useSelector(selectUser), identity = _f.identity, isModerator = _f.isModerator, isChairman = _f.isChairman;
    var shouldRenderVW = !isEmpty(identity) && !active && has && !isModerator && !isChairman;
    var episodesAreLoaded = episodesLoading === "idle" || episodesLoading === "succeeded";
    var forceHideRegistrationForm = useSelector(selectForceHideRegistrationForm);
    var baseClass = "registration-form";
    var titleRef = useRef(null);
    var titleAnimationClass = useAnimationClassName({ ref: titleRef, animation: animation });
    var contentRef = useRef(null);
    var contentAnimationClass = useAnimationClassName({ ref: contentRef, animation: animation });
    var legalsFields = legals.map(function (legal, index) { return (__assign(__assign({}, legal), { key: "legal-".concat(index), label: _jsx(SanitizeHtml, { stringToSanitize: legal.label, legalsIndex: index, tag: "span" }), hideAsterisk: legal.hide_asterisk, rules: [
            {
                validator: function (rule, value) { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        if (legal.required && !value) {
                            throw new Error(contents.field_required);
                        }
                        return [2 /*return*/];
                    });
                }); }
            }
        ] })); });
    var _g = useState(false), isAvailableRegistration = _g[0], setIsAvailableRegistration = _g[1];
    useEffect(function () {
        var episodesWithOpenSubscription = episodes && episodes.filter(function (item) { return item.is_subscriptions_open; });
        setIsAvailableRegistration(episodesWithOpenSubscription.length >= 1 && episodesAreLoaded && !!!forceHideRegistrationForm ? true : false);
    }, [episodes, forceHideRegistrationForm]);
    var onSubmitValues = function (values) {
        dispatch(displayModal({
            name: CONFIRM_EMAIL_ON_REGISTER_MODAL,
            props: {
                email: values.email,
                callback: function () {
                    dispatch(registerUserThunk(values))
                        .unwrap()
                        .then(function (payload) {
                        onSuccessCallback && onSuccessCallback(payload);
                        dispatch(displayModal({
                            name: THANK_YOU_MODAL,
                            props: { content: contents.thankyou_message, footer: _jsx(OpenLoginModalButton, {}) }
                        }));
                    })
                        .catch(function (error) {
                        if (error.status === 422) {
                            var valuesWithErrors = mapErrorsToFields({ error: error.data, values: values });
                            form.setFields(valuesWithErrors);
                        }
                    });
                }
            }
        }));
    };
    var registrationFormClass = classNames(baseClass, (_b = {},
        _b["".concat(baseClass, "--no-background")] = props.noBackground,
        _b));
    return (_jsx(BlockWrapper, __assign({ className: registrationFormClass }, props, { children: loadingRegister === "succeeded" ? (_jsx(Space, __assign({ direction: "vertical" }, { children: _jsx(SanitizeHtml, { stringToSanitize: contents.thankyou_message, tag: "div", className: "registration-form__thankYouMessage" }) }))) : (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ ref: titleRef, className: titleAnimationClass }, { children: [!props.hideLogo && (media === null || media === void 0 ? void 0 : media.registration_form_logo_desktop) && (_jsx(Title, __assign({ level: 2 }, { children: _jsx(Logo, { align: "left", src: (_c = media === null || media === void 0 ? void 0 : media.registration_form_logo_desktop) === null || _c === void 0 ? void 0 : _c.url }) }))), contents.registration_intro && (_jsx(Title, __assign({ className: "".concat(baseClass, "__title"), level: 2 }, { children: contents.registration_intro }))), contents.registration_header && _jsx(SanitizeHtml, { stringToSanitize: contents.registration_header, tag: "div" }), contents.registration_form_title && _jsx(Title, __assign({ level: 4 }, { children: contents.registration_form_title }))] })), _jsxs("div", __assign({ ref: contentRef, className: contentAnimationClass }, { children: [isAvailableRegistration && (_jsxs(Form, __assign({ form: form, onFinish: onSubmitValues, onValuesChange: typeof onChange === "function" ? onChange : undefined, layout: "vertical", className: "registration-form__form", scrollToFirstError: true }, { children: [inputs.map(function (_a, index) {
                                    var type = _a.type, options = _a.options, value = _a.value, label = _a.label, required = _a.required, rules = _a.rules, name = _a.name, showSearch = _a.showSearch;
                                    return (_jsxs(Col, __assign({ span: 24 }, { children: [type === "select" && (_jsx(Item, __assign({ label: label, name: name, required: required, rules: rules, initialValue: value }, { children: _jsx(Select, { "data-testid": "registration-form-item", showSearch: showSearch, optionFilterProp: showSearch ? "label" : undefined, options: options === null || options === void 0 ? void 0 : options.map(function (option) { return ({
                                                        value: option.value,
                                                        label: option.label
                                                    }); }) }) }))), (type === "text" || type === "email") && (_jsx(Item, __assign({ initialValue: value, label: label, name: name, required: required, rules: rules }, { children: _jsx(Input, { id: name, type: type, "data-testid": "registration-form-item" }) }))), type === "password" && (_jsx(Item, __assign({ initialValue: value, label: label, name: name, required: required, rules: rules }, { children: _jsx(Password, { id: name }) }))), type === "hidden" && (_jsx(Item, __assign({ initialValue: value, name: name, noStyle: true }, { children: _jsx(Input, { type: type, "data-testid": "registration-form-item" }) })))] }), "input-".concat(index)));
                                }), contents.registration_footer && (_jsx(SanitizeHtml, { stringToSanitize: contents.registration_footer, tag: "div", className: "registration-form__footer" })), legalsFields && (_jsx("div", { children: legalsFields.map(function (_a, index) {
                                        var type = _a.type, text = _a.text, name = _a.name, value = _a.value, label = _a.label, required = _a.required, rules = _a.rules, hideAsterisk = _a.hideAsterisk, key = _a.key;
                                        return (_jsxs("div", { children: [type === "checkbox" && (_jsx(Item, __assign({ name: name, initialValue: value, required: required, rules: rules, valuePropName: "checked" }, { children: _jsx(Checkbox, __assign({ clickableLabel: false, "data-testid": "registration-form-item" }, { children: label })) }), key)), type === "plaintext" && _jsx(Text, { children: text })] }, index));
                                    }) })), _jsxs("div", __assign({ className: "registration-form__required-helper" }, { children: ["* ", contents.required_fields] })), _jsx(Button, __assign({ className: "".concat(baseClass, "__submit-button"), loading: registerLoading === "pending", htmlType: "submit", type: "primary" }, { children: contents.register }))] }))), !isAvailableRegistration ? (_jsx(SanitizeHtml, { className: "registration-form__closed", stringToSanitize: contents.registration_closed, tag: "div" })) : (_jsx(Space, __assign({ direction: "vertical", style: { marginTop: 20 } }, { children: contents.registration_footer_help_contact && (_jsx(SanitizeHtml, { tag: "div", stringToSanitize: contents.registration_footer_help_contact })) }))), !props.hideGoToLogin && (_jsx("div", __assign({ className: "registration-form__another-content", onClick: function () {
                                if (isEmpty(identity))
                                    dispatch(displayModal({
                                        name: LOGIN_MODAL
                                    }));
                                else if (shouldRenderVW)
                                    dispatch(displayModal({
                                        name: VW_MODAL
                                    }));
                            } }, { children: _jsx(SanitizeHtml, { stringToSanitize: contents.go_to_login_form, tag: "div" }) })))] }))] })) })));
};
export default RegistrationForm;
