import { changeIsInternalUser, selectAppContent, selectAppLanguages, selectCountries, selectEventCountries, selectIsInternalUser, selectDefaultLanguage, selectLocaleLanguage, selectRegistrationFormHasInternal, selectLayout } from "@adm-media/adam-store-plugin";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useChangeAppLocale } from "./useChangeAppLocale";
import { useAppDispatch } from "../store";
import { convertTimezonesToAntdOptionList, findCountry } from "../utils/array";
var useRegistrationForm = function (args) {
    var dispatch = useAppDispatch();
    var changeAppLocale = useChangeAppLocale().changeAppLocale;
    var _a = useState(undefined), langInputValue = _a[0], changeLangInputValue = _a[1];
    var _b = useState(undefined), countryInputValue = _b[0], changeCountryInputValue = _b[1];
    var _c = useState(undefined), departmentInputValue = _c[0], changeDepartmentInputValue = _c[1];
    var _d = useState(undefined), specialtyInputValue = _d[0], changeSpecialtyInputValue = _d[1];
    var _e = useState([]), timezonesSelectedCountry = _e[0], setTimezonesSelectedCountry = _e[1];
    var _f = useState(undefined), roleInputValue = _f[0], changeRoleInputValue = _f[1];
    var _g = useState(undefined), infoAboutEventInputValue = _g[0], changeInfoAboutEventInputValue = _g[1];
    var eventDefaultLanguage = useSelector(selectDefaultLanguage);
    var countries = useSelector(selectCountries);
    var languages = useSelector(selectAppLanguages);
    var eventCountries = useSelector(selectEventCountries);
    var isInternalUser = useSelector(selectIsInternalUser);
    var contents = useSelector(selectAppContent);
    var publicLocaleLanguage = useSelector(selectLocaleLanguage);
    var hasInternal = useSelector(selectRegistrationFormHasInternal);
    var registrationFormSpecialties = useSelector(selectLayout).registrationFormSpecialties;
    var getTranslatedOptions = function (optionsList) {
        var _a, _b;
        if (optionsList) {
            return Object.keys(optionsList).map(function (key) { return ({
                value: key,
                label: optionsList[key]
            }); });
        }
        var currentLang = eventDefaultLanguage && ((_a = Object.keys(registrationFormSpecialties || {})) === null || _a === void 0 ? void 0 : _a.includes(eventDefaultLanguage)) ? eventDefaultLanguage : "en";
        if (Object.keys((registrationFormSpecialties === null || registrationFormSpecialties === void 0 ? void 0 : registrationFormSpecialties[currentLang]) || {}).length === 0) {
            return [
                {
                    label: "Other (Please specify)",
                    value: "Other"
                }
            ];
        }
        else {
            return (_b = Object.keys(registrationFormSpecialties === null || registrationFormSpecialties === void 0 ? void 0 : registrationFormSpecialties[currentLang])) === null || _b === void 0 ? void 0 : _b.map(function (key) { return ({
                value: key,
                label: registrationFormSpecialties === null || registrationFormSpecialties === void 0 ? void 0 : registrationFormSpecialties[currentLang][key]
            }); });
        }
    };
    var getLangForSelect = function () {
        var languageInput = {
            type: "hidden",
            name: "lang_id",
            value: languages[0].value
        };
        if (languages.length > 1) {
            languageInput = {
                type: "select",
                label: contents.language,
                required: true,
                name: "lang_id",
                options: languages,
                value: publicLocaleLanguage,
                rules: [
                    {
                        required: true,
                        message: "Please select your language!"
                    }
                ]
            };
        }
        return languageInput;
    };
    var getRegistrationFormProps = function (_a) {
        var inputs = _a.inputs;
        return {
            inputs: inputs,
            onChange: function (changed) {
                if (Object.keys(changed).includes("specialty")) {
                    changeSpecialtyInputValue(changed["specialty"]);
                }
                if (Object.keys(changed).includes("department")) {
                    changeDepartmentInputValue(changed["department"]);
                }
                // Language selection trigger
                if (Object.keys(changed).includes("lang_id")) {
                    changeLangInputValue(changed["lang_id"]);
                    if (changed["lang_id"] !== publicLocaleLanguage)
                        changeAppLocale({ newLocale: changed["lang_id"] });
                }
                // TODO: check this logic. Dependent on change order?
                if (Object.keys(changed).includes("country_id")) {
                    changeCountryInputValue(changed["country_id"]);
                    var selectedCountry = findCountry(changed["country_id"], countries);
                    setTimezonesSelectedCountry(Array.isArray(selectedCountry === null || selectedCountry === void 0 ? void 0 : selectedCountry.timezones) ? selectedCountry === null || selectedCountry === void 0 ? void 0 : selectedCountry.timezones : []);
                }
                if (Object.keys(changed).includes("country_id_of_work")) {
                    changeCountryInputValue(changed["country_id_of_work"]);
                    var selectedCountry = findCountry(changed["country_id_of_work"], countries);
                    setTimezonesSelectedCountry(Array.isArray(selectedCountry === null || selectedCountry === void 0 ? void 0 : selectedCountry.timezones) ? selectedCountry === null || selectedCountry === void 0 ? void 0 : selectedCountry.timezones : []);
                }
                if (Object.keys(changed).includes("is_internal")) {
                    dispatch(changeIsInternalUser(!!changed["is_internal"]));
                }
                if (Object.keys(changed).includes("role")) {
                    changeRoleInputValue(changed["role"]);
                }
                if (Object.keys(changed).includes("info_about_event")) {
                    changeInfoAboutEventInputValue(changed["info_about_event"]);
                }
                if (typeof (args === null || args === void 0 ? void 0 : args.onChange) === "function")
                    args.onChange(changed);
            }
        };
    };
    return {
        contents: contents,
        countries: countries,
        eventCountries: eventCountries,
        hasInternal: hasInternal,
        isInternalUser: isInternalUser,
        languages: languages,
        langId: eventDefaultLanguage,
        languageInput: getLangForSelect(),
        timezonesSelectedCountry: convertTimezonesToAntdOptionList(timezonesSelectedCountry),
        specialtyInputValue: specialtyInputValue,
        departmentInputValue: departmentInputValue,
        countryInputValue: countryInputValue,
        langInputValue: langInputValue,
        roleInputValue: roleInputValue,
        infoAboutEventInputValue: infoAboutEventInputValue,
        changeSpecialtyInputValue: function (value) { return changeSpecialtyInputValue(value); },
        changeDepartmentInputValue: function (value) { return changeDepartmentInputValue(value); },
        changeCountryInputValue: function (value) { return changeCountryInputValue(value); },
        changeLangInputValue: function (value) { return changeLangInputValue(value); },
        getTranslatedOptions: function (optionsList) { return getTranslatedOptions(optionsList); },
        getRegistrationFormProps: function (_a) {
            var inputs = _a.inputs;
            return getRegistrationFormProps({ inputs: inputs });
        }
    };
};
export default useRegistrationForm;
