var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { selectAppContent } from "@adm-media/adam-store-plugin";
import classNames from "classnames";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { BlockWrapper } from "../../wrappers/BlockWrapper/BlockWrapper";
import { SanitizeHtml } from "../../ui/SanitizeHtml/SanitizeHtml";
import { useAnimationClassName } from "../../../hooks/useAnimationClassName";
import "./Contacts.scss";
var Contacts = function (_a) {
    var _b = _a.centered, centered = _b === void 0 ? true : _b, animation = _a.animation, props = __rest(_a, ["centered", "animation"]);
    var contents = useSelector(selectAppContent);
    var contentRef = useRef(null);
    var contentAnimationClass = useAnimationClassName({ ref: contentRef, animation: animation });
    var className = classNames("websiteContacts", { centered: centered });
    return (_jsx(BlockWrapper, __assign({}, props, { className: className, "data-testid": "block-contacts" }, { children: _jsx("div", __assign({ ref: contentRef, className: contentAnimationClass }, { children: _jsx(SanitizeHtml, { className: "websiteContacts__container", stringToSanitize: contents.website_contacts, tag: "div" }) })) })));
};
export default Contacts;
