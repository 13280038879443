var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { hideModal, selectAppContent } from "@adm-media/adam-store-plugin";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../ui/Buttons/Button";
import Typography from "../../ui/Typography/Typography";
import "./ConfirmEmailOnRegister.scss";
import { CONFIRM_EMAIL_ON_REGISTER_MODAL } from "..";
import Space from "../../ui/Space/Space";
import { SanitizeHtml } from "../../ui/SanitizeHtml/SanitizeHtml";
var Paragraph = Typography.Paragraph, Text = Typography.Text;
export var ConfirmEmailOnRegisterModal = function (_a) {
    var email = _a.email, callback = _a.callback;
    var dispatch = useDispatch();
    var contents = useSelector(selectAppContent);
    var handleCloseModal = function () { return dispatch(hideModal({ name: CONFIRM_EMAIL_ON_REGISTER_MODAL })); };
    var handleConfirmEmail = function () {
        callback();
        handleCloseModal();
    };
    return (_jsxs(_Fragment, { children: [_jsx(Paragraph, { children: _jsx(SanitizeHtml, { stringToSanitize: contents.check_your_email_message || "Please check that your email is correct", tag: "span" }) }), _jsx(Paragraph, __assign({ className: "confirm-registration-email-modal__email" }, { children: email })), _jsxs(Space, __assign({ direction: "horizontal" }, { children: [_jsx(Button, __assign({ onClick: handleCloseModal, className: "confirm-registration-email-modal__button-cancel" }, { children: contents.no_take_me_back })), _jsx(Button, __assign({ onClick: handleConfirmEmail, type: "primary", className: "confirm-registration-email-modal__button-confirm" }, { children: contents.yes_correct }))] }))] }));
};
export var getConfirmEmailOnRegisterModalProps = function () {
    return {
        modalClassName: "confirm-registration-email-modal"
    };
};
