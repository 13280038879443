import RegistrationForm from "../blocks/RegistrationForm/RegistrationForm";
import { LoginForm } from "../blocks/LoginForm/LoginForm";
import Footer from "../blocks/Footer/Footer";
import Navbar from "../blocks/Navbar/Navbar";
import { Speakers } from "../blocks/Speakers/Speakers";
import Contacts from "../blocks/Contacts/Contacts";
import Hero from "../blocks/Hero/Hero";
import { Agenda } from "../blocks/Agenda/Agenda";
import { Episodes } from "../blocks/Episodes/Episodes";
import Row from "../ui/Row/Row";
import { OpenLoginModalButton } from "../ui/Buttons/OpenLoginModalButton/OpenLoginModalButton";
var UI = {
    // blocks
    RegistrationForm: RegistrationForm,
    LoginForm: LoginForm,
    Footer: Footer,
    Navbar: Navbar,
    SpeakersList: Speakers,
    Contacts: Contacts,
    Hero: Hero,
    Agenda: Agenda,
    Episodes: Episodes,
    //ui
    Row: Row,
    LoginButton: OpenLoginModalButton
};
export default UI;
