var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { selectAppLanguages, selectStyles } from "@adm-media/adam-store-plugin";
import { useSelector } from "react-redux";
import { OpenLoginModalButton } from "../../ui/Buttons/OpenLoginModalButton/OpenLoginModalButton";
import SelectLang from "../../ui/Select/SelectLang/SelectLang";
import { DownloadICSButton } from "../../ui/Buttons/DownloadICSButton/DownloadICSButton";
var enrichNavbarProps = function (_a) {
    var props = __rest(_a, []);
    var styles = useSelector(selectStyles);
    var availableLanguages = useSelector(selectAppLanguages);
    var actions = [];
    if ((availableLanguages === null || availableLanguages === void 0 ? void 0 : availableLanguages.length) > 1)
        actions = __spreadArray(__spreadArray([], actions, true), [_jsx(SelectLang, {}, "withSelectLang")], false);
    if (props.withLoginButton)
        actions = __spreadArray(__spreadArray([], actions, true), [_jsx(OpenLoginModalButton, { "data-testid": "navbar-login-button", className: "navbar-login-button" }, "login-button")], false);
    if (styles === null || styles === void 0 ? void 0 : styles.add_save_the_date_button)
        actions = __spreadArray([_jsx(DownloadICSButton, {}, "download-ics-button")], actions, true);
    return __assign(__assign({}, props), { actions: actions, verticalSpacing: "small" });
};
export default enrichNavbarProps;
