var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "classnames";
import { ExpandIcon } from "../../ui/Icons/Icons";
import Collapse from "../Collapse/Collapse";
import { SanitizeHtml } from "../SanitizeHtml/SanitizeHtml";
import Typography from "../Typography/Typography";
import "./SpeakerCard.scss";
var Title = Typography.Title;
var Panel = Collapse.Panel;
export var SpeakerCard = function (_a) {
    var avatar = _a.avatar, name = _a.name, role = _a.role, description = _a.description, size = _a.size, descriptionVisible = _a.descriptionVisible;
    return (_jsxs("div", __assign({ className: "speaker-card" }, { children: [_jsx("div", __assign({ className: "speaker-card__avatar-wrapper" }, { children: _jsx("div", { className: classNames("speaker-card__avatar", size), style: { backgroundImage: "url(".concat(avatar || "https://via.placeholder.com/200", ")"), margin: "0 auto" }, "data-testid": "speaker-avatar" }) })), _jsxs("div", __assign({ className: "speaker-card__content-wrapper" }, { children: [_jsx(Title, __assign({ className: "speaker-card__name", level: 5 }, { children: name })), role && !description && _jsx(SanitizeHtml, { className: "speaker-card__role", stringToSanitize: role, tag: "div" }), description && role && (_jsx(Collapse, { expandIcon: function (_a) {
                            var isActive = _a.isActive;
                            return _jsx(ExpandIcon, { onClick: function () { }, expanded: !!isActive, "data-testid": "speakers-expand-button" });
                        }, ghost: true, expandIconPosition: "end", defaultActiveKey: descriptionVisible ? name : undefined, items: [
                            {
                                key: name,
                                label: _jsx(SanitizeHtml, { stringToSanitize: role, tag: "div", className: "speaker-card__role" }),
                                children: _jsx(SanitizeHtml, { className: "speaker-card__description", stringToSanitize: description, tag: "div" })
                            }
                        ] }))] }))] })));
};
