var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import Col from "../../ui/Col/Col";
import "./BlockWrapper.scss";
var getDimension = function (dimension) {
    var allowedDimensions = ["1", "1-2", "1-3", "2-3"];
    if (allowedDimensions.includes(dimension))
        return dimension;
    else
        return "1";
};
var getAlignment = function (alignment) {
    var allowedAlignments = ["l", "r"];
    if (allowedAlignments.includes(alignment))
        return alignment;
    else
        return "";
};
export var BlockWrapper = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k;
    var noGutter = _a.noGutter, children = _a.children, className = _a.className, _l = _a.verticalSpacing, verticalSpacing = _l === void 0 ? "medium" : _l, props = __rest(_a, ["noGutter", "children", "className", "verticalSpacing"]);
    var getColumnWidth = function (dimension) {
        if (dimension === "1-2")
            return 12;
        else if (dimension === "1-3")
            return 8;
        else if (dimension === "2-3")
            return 16;
        else
            return 24;
    };
    return (_jsx(Col, __assign({ xs: getColumnWidth((_b = props === null || props === void 0 ? void 0 : props.xs) === null || _b === void 0 ? void 0 : _b.dimension), md: getColumnWidth((_c = props === null || props === void 0 ? void 0 : props.md) === null || _c === void 0 ? void 0 : _c.dimension), xl: getColumnWidth((_d = props === null || props === void 0 ? void 0 : props.xl) === null || _d === void 0 ? void 0 : _d.dimension), className: className, "data-testid": (props === null || props === void 0 ? void 0 : props["data-testid"]) || undefined }, { children: _jsx("div", __assign({ className: "block-wrapper-container block-wrapper-container--vertical-spacing-".concat(verticalSpacing) }, { children: noGutter ? (_jsx("div", { children: children })) : (_jsx("div", __assign({ className: "block-wrapper block-wrapper--xs-".concat(getDimension((_e = props === null || props === void 0 ? void 0 : props.xs) === null || _e === void 0 ? void 0 : _e.dimension)).concat(getAlignment((_f = props === null || props === void 0 ? void 0 : props.xs) === null || _f === void 0 ? void 0 : _f.alignment), " \n                        block-wrapper--md-").concat(getDimension((_g = props === null || props === void 0 ? void 0 : props.md) === null || _g === void 0 ? void 0 : _g.dimension)).concat(getAlignment((_h = props === null || props === void 0 ? void 0 : props.md) === null || _h === void 0 ? void 0 : _h.alignment), "\n                        block-wrapper--xl-").concat(getDimension((_j = props === null || props === void 0 ? void 0 : props.xl) === null || _j === void 0 ? void 0 : _j.dimension)).concat(getAlignment((_k = props === null || props === void 0 ? void 0 : props.xl) === null || _k === void 0 ? void 0 : _k.alignment)) }, { children: children }))) })) })));
};
