var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import Card from "../Card/Card";
import Avatar from "../Avatar/Avatar";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { SPEAKER_DETAILS_MODAL } from "../../modals";
import { displayModal } from "@adm-media/adam-store-plugin";
import { CSSTransition } from "react-transition-group";
import { SanitizeHtml } from "../SanitizeHtml/SanitizeHtml";
import Typography from "../Typography/Typography";
import "./AgendaItem.scss";
var Meta = Card.Meta;
var Title = Typography.Title, Text = Typography.Text;
export var AgendaItem = function (_a) {
    var _b;
    var withAnimation = _a.withAnimation, timeout = _a.timeout, hiddenSpeakersId = _a.hiddenSpeakersId, agendaItem = _a.agendaItem, _c = _a.withBorder, withBorder = _c === void 0 ? false : _c, _d = _a.type, type = _d === void 0 ? "card" : _d, props = __rest(_a, ["withAnimation", "timeout", "hiddenSpeakersId", "agendaItem", "withBorder", "type"]);
    var baseClass = "agenda-card";
    var dispatch = useDispatch();
    var _e = useState(false), visible = _e[0], setVisible = _e[1];
    useEffect(function () {
        var timeoutVisible = setTimeout(function () {
            setVisible(true);
        }, timeout);
        return function () {
            clearTimeout(timeoutVisible);
        };
    }, []);
    var ItemWrapper = type === "card" ? Card : "div";
    var speakersToShow = hiddenSpeakersId
        ? (_b = agendaItem === null || agendaItem === void 0 ? void 0 : agendaItem.speakers) === null || _b === void 0 ? void 0 : _b.filter(function (speaker) { return !(hiddenSpeakersId === null || hiddenSpeakersId === void 0 ? void 0 : hiddenSpeakersId.includes(speaker.id)); })
        : agendaItem === null || agendaItem === void 0 ? void 0 : agendaItem.speakers;
    var agendaElement = (_jsx(ItemWrapper, __assign({ className: classNames("".concat(baseClass, "__content"), "".concat(baseClass, "__content--").concat(type), withAnimation), bordered: type === "card" ? withBorder : undefined }, { children: _jsxs("div", __assign({ className: "".concat(baseClass, "__wrapper") }, { children: [type !== "card" && (_jsxs("div", __assign({ className: "".concat(baseClass, "__date-info-wrapper") }, { children: [_jsx("div", __assign({ className: "".concat(baseClass, "__start-time") }, { children: agendaItem.start_at })), _jsxs("div", __assign({ className: "".concat(baseClass, "__duration") }, { children: ["(", agendaItem.label, ")"] }))] }))), _jsxs("div", __assign({ className: "".concat(baseClass, "__texts") }, { children: [_jsx(Title, __assign({ level: props.titleLevel || 4, className: "".concat(baseClass, "__title") }, { children: _jsx(SanitizeHtml, { stringToSanitize: agendaItem.title, tag: "div" }) })), type === "card" && (_jsxs("div", __assign({ className: "".concat(baseClass, "__date-tag") }, { children: [_jsx("span", __assign({ className: "".concat(baseClass, "__time") }, { children: agendaItem.start_at })), " ", _jsxs("span", __assign({ className: "".concat(baseClass, "__timing") }, { children: ["(", agendaItem.label, ")"] }))] }))), agendaItem.description && (_jsx(SanitizeHtml, { className: "".concat(baseClass, "__paragraph"), stringToSanitize: agendaItem.description, tag: "div" })), Array.isArray(speakersToShow) && speakersToShow.length > 0 && (_jsx("div", __assign({ className: "".concat(baseClass, "__speakers-container") }, { children: speakersToShow === null || speakersToShow === void 0 ? void 0 : speakersToShow.map(function (speaker) {
                                return (_jsx("div", __assign({ className: "".concat(baseClass, "__speaker-wrapper"), onClick: function () {
                                        return dispatch(displayModal({
                                            name: SPEAKER_DETAILS_MODAL,
                                            props: speaker
                                        }));
                                    } }, { children: _jsx(Meta, { className: "".concat(baseClass, "__speaker"), avatar: _jsx(Avatar, { src: speaker.media_url }), title: "".concat(speaker.firstname, " ").concat(speaker.lastname) }) }), speaker.id));
                            }) })))] }))] })) })));
    return (_jsx("div", __assign({ className: classNames(baseClass, { withAnimation: withAnimation }) }, { children: withAnimation ? (_jsx(CSSTransition, __assign({ appear: true, in: visible, timeout: 10000 }, { children: agendaElement }))) : (agendaElement) })));
};
