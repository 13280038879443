var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import Typography from "../../../ui/Typography/Typography";
import Form from "../../../ui/Form/Form";
import Input from "../../../ui/Input/Input";
import Button from "../../../ui/Buttons/Button";
import { useSelector } from "react-redux";
import { passwordForgotThunk, selectAppContent, selectPasswordForgot } from "@adm-media/adam-store-plugin";
import { SanitizeHtml } from "../../../ui/SanitizeHtml/SanitizeHtml";
import { useAppDispatch } from "../../../../store";
import { useNotification } from "../../../../hooks/useNotification";
var Item = Form.Item;
var Link = Typography.Link;
var ForgotPassword = function (_a) {
    var setIsForgotPassword = _a.setIsForgotPassword;
    var dispatch = useAppDispatch();
    var form = Form.useForm()[0];
    var notification = useNotification().notification;
    var loadingPasswordForgot = useSelector(selectPasswordForgot).loading;
    var contents = useSelector(selectAppContent);
    var _b = useState(false), passwordResetEamailSent = _b[0], setPasswordResetEamailSent = _b[1];
    var handleResetPsw = function (_a) {
        var email = _a.email;
        dispatch(passwordForgotThunk(email))
            .unwrap()
            .then(function () { return setPasswordResetEamailSent(true); })
            .catch(function () {
            notification.showGenericError();
            form.resetFields();
        });
    };
    return (_jsx(Form, __assign({ form: form, onFinish: handleResetPsw, layout: "vertical" }, { children: passwordResetEamailSent ? (_jsx("div", __assign({ "data-testid": "forgot-password-email-sent-text" }, { children: _jsx(SanitizeHtml, { stringToSanitize: contents.reset_password_sent_text || "The eset password email was sent", tag: "div" }) }))) : (_jsxs(_Fragment, { children: [_jsx(Item, __assign({ "data-testid": "forgot-password-email-field", name: "email", label: contents.email, rules: [
                        {
                            type: "email",
                            required: true,
                            message: contents.error_email_invalid,
                            validateTrigger: "onSubmit"
                        }
                    ], extra: _jsx(SanitizeHtml, { stringToSanitize: contents.reset_password_instructions_text, tag: "div" }) }, { children: _jsx(Input, { placeholder: contents.email, autoComplete: "on", type: "email" }) })), _jsx(Item, { children: _jsx(Button, __assign({ "data-testid": "request-password-reset-link-button", loading: loadingPasswordForgot === "pending", className: "login-form__submit-button", htmlType: "submit" }, { children: contents.request_reset_password_link || "Request a password reset link via email" })) }), setIsForgotPassword && (_jsx(Link, __assign({ style: { fontWeight: "bold", cursor: "pointer", textDecoration: "underline" }, onClick: function () {
                        setIsForgotPassword && setIsForgotPassword(false);
                    } }, { children: contents.go_back || "Go back" })))] })) })));
};
export default ForgotPassword;
